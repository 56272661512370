import { Controller } from '@hotwired/stimulus';
import { post, put } from '@rails/request.js';
import debounce from 'lodash.debounce';
import { nanoid } from 'nanoid';

let Dashboard = null;
let uploadedFileData = null;
let uppyInstance = null;

let DEBOUNCE_DELAY = 500; // ms

export default class extends Controller {
  static targets = [
    'input',
    'form',
    'nextButton',
    'typeField',
    'photo',
    'croppedPhoto',
  ];
  static values = {
    maxFileSize: Number,
    minPhotos: Number,
    maxPhotos: Number,
    subjectsPath: String,
    server: String,
  };

  initialize() {
    this.numPendingRequests = 0;

    (async () => {
      Dashboard = (await import('@uppy/dashboard')).default;

      let uppyModule = await import('../src/uppy');
      uploadedFileData = uppyModule.uploadedFileData;
      uppyInstance = uppyModule.uppyInstance;

      this.uppy = this.createUppy();
      this.addUppyEvents();
      this.updateUppyAndNextButton();

      window.addEventListener('online', this.handleOnlineStatus);
    })();
  }

  disconnect() {
    this.uppy.close();
    window.removeEventListener('online', this.handleOnlineStatus);
  }

  // If internet goes offline then back online, reset pending requests, retry upload, re-save form.
  handleOnlineStatus = () => {
    this.numPendingRequests = 0;
    this.uppy.retryAll();
    this.saveForm();
  };

  createUppy = () => {
    return uppyInstance({
      id: this.inputTarget.id,
      server: this.serverValue,
      restrictions: {
        allowedFileTypes: ['image/*', '.heic', '.heif'],
        maxFileSize: this.maxFileSizeValue,
      },
    }).use(Dashboard, {
      target: this.inputTarget,
      inline: true,
      replaceTargetContent: true,
      showProgressDetails: true,
      width: '100%',
      height: 200,
    });
  }

  addUppyEvents() {
    this.uppy.on('upload', (data) => {
      this.numPendingRequests += data.fileIDs.length;
      this.nextButtonTarget.disabled = true;
    });

    this.uppy.on('upload-success', (file, response) => {
      let formData = new FormData(this.formTarget);
      formData.append(
        `subject[photos_attributes][${nanoid()}][image]`,
        uploadedFileData(file, response, this.serverValue),
      );
      this.savePhoto(formData, () => {
        // Occasionally, this can trigger an error if the file is removed before Uppy gets a chance
        // to create the thumbnail. We don't really care, we just don't want files to stay inside of
        // Uppy dashboard.
        this.uppy.removeFile(file.id);
      });
    });
  }

  photoTargetConnected = () => this.updateUppyAndNextButton();

  photoTargetDisconnected = () => this.updateUppyAndNextButton();

  croppedPhotoTargetConnected = () => this.updateNextButton();

  numPhotosRemaining = () => this.maxPhotosValue - this.photoTargets.length;

  pendingCroppedPhotos = () => this.photoTargets.length - this.croppedPhotoTargets.length !== 0;

  noTypeSelected = () => this.typeFieldTargets.every((el) => !el.checked);

  uppyDisabled = () => this.noTypeSelected() || this.numPhotosRemaining() == 0;

  decrementPendingRequests = () => {
    this.numPendingRequests = Math.max(0, this.numPendingRequests - 1);
  };

  debounceSaveFormRequest = debounce(() => {
    this.numPendingRequests++;
    put(this.subjectsPathValue, {
      body: new FormData(this.formTarget),
      responseKind: 'turbo-stream',
    }).then(() => {
      this.decrementPendingRequests();
      this.updateUppyAndNextButton();
    });
  }, DEBOUNCE_DELAY);

  saveForm() {
    this.nextButtonTarget.disabled = true;
    this.debounceSaveFormRequest();
  }

  savePhoto(data, callback) {
    post(this.subjectsPathValue, {
      body: data,
      responseKind: 'turbo-stream',
    }).then(() => {
      this.decrementPendingRequests();
      callback();
    });
  }

  uppyDashboardPrompt() {
    if (this.noTypeSelected()) {
      return 'Select a pet type above before uploading photos.';
    } else if (this.numPhotosRemaining() == 0) {
      return `You have reached the maximum number of photos (${this.maxPhotosValue}).`;
    }
    return 'Drop files here or %{browseFiles}';
  }

  // Update maximum files allowed in Uppy and the corresponding text in the Dashboard
  updateUppy() {
    if (this.uppy && this.uppy.getPlugin('Dashboard')) {
      this.uppy.setOptions({
        restrictions: { maxNumberOfFiles: this.numPhotosRemaining() },
      });
      this.uppy.getPlugin('Dashboard').setOptions({
        disabled: this.uppyDisabled(),
        locale: {
          strings: {
            dropPasteFiles: this.uppyDashboardPrompt(),
          },
        },
      });
    }
  }

  // Disable Next button if there are pending requests, min num photos is not met, or we're waiting
  // on the cropped photos.
  updateNextButton() {
    const disableNextButton =
      this.numPendingRequests !== 0 || this.pendingCroppedPhotos();
    this.nextButtonTarget.disabled = disableNextButton;
    this.notifySubjectFormOfChange();
  }

  updateUppyAndNextButton() {
    this.updateUppy();
    this.updateNextButton();
  }

  notifySubjectFormOfChange() {
    const subjectFormController = this.application.getControllerForElementAndIdentifier(
      this.formTarget,
      'subject-form',
    );
    subjectFormController && subjectFormController.handlePhotoCountChanged(this.photoTargets.length);
  }
}
