import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

let DEFAULT_POLL_INTERVAL = 30000; // 30 seconds

// Connects to data-controller="turbo-stream-polling"
export default class extends Controller {
  static values = { url: String, pollInterval: Number, redirectUrl: String };

  connect() {
    if (!this.urlValue) return;

    setTimeout(() => {
      let request = get(this.urlValue, { responseKind: 'turbo-stream', redirect: 'manual'});
      request.then((fetchResponse) => {
        if(fetchResponse.response.type == 'opaqueredirect'){
          if(this.redirectUrlValue) {
            window.location = this.redirectUrlValue;
          }
        }
      });
    }, this.pollIntervalValue || DEFAULT_POLL_INTERVAL);
  }
}
