import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turbo-frame-missing-redirect"
// Generically handle missing turbo frame by redirecting to the response url
// turbo_page_requires_reload is an alternative but will lose the flash message
export default class extends Controller {
  connect() {
    this.element.addEventListener('turbo:frame-missing', (event) => {
      window.location = event.detail.response.url;
    });
  }
}
