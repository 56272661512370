import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    if (typeof navigator.share === 'undefined') {
      return;
    }
    this.element.classList.remove('hidden');
    this.element.classList.add('block');
  }
}
