// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import ahoy from "ahoy.js"
import "~/controllers"
import '~/lib/turbo_stream_actions';

import posthog from 'posthog-js'

let environment = document.querySelector("head").dataset.environment;
let currentUserId = document.querySelector("head").dataset.currentUserId;
let currentUserEmail = document.querySelector("head").dataset.currentUserEmail;

let posthogKey = document.querySelector("head").dataset.phk;
if(posthogKey) {
    posthog.init(posthogKey, { api_host: 'https://app.posthog.com' })
    if(environment == "production" || environment == "staging") {
        if(currentUserId) {
            posthog.identify(currentUserId, {
                email: currentUserEmail,
            })
        }
    }
}

import * as Sentry from '@sentry/browser';
let revision = document.querySelector("head").dataset.revision;
let sentryDsn = document.querySelector("head").dataset.sentryDsn;
let sentryOrg = 'volition';
let sentryProjectId = sentryDsn.split("/")[-1];

if(sentryDsn) {
    Sentry.init({
        dsn: sentryDsn,
        environment: environment,
        integrations: [new Sentry.BrowserTracing(), new posthog.SentryIntegration(posthog, sentryOrg, sentryProjectId)],
        release: revision,
        tracesSampleRate: 0.2,
    });

    // https://docs.sentry.io/platforms/javascript/enriching-events/identify-user/
    if(currentUserId) {
        Sentry.setUser({
            id: currentUserId,
            email: currentUserEmail,
        });
    }
}