import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mini-gallery"
export default class extends Controller {
  static targets = ['scroller', 'leftButton']
  
  moveRight() {
    this.scrollerTarget.scrollBy({
        top: 0,
        left: 200,
        behavior: 'smooth'
    });
    if (!this.hasLeftButtonTarget) return;
    this.leftButtonTarget.classList.remove('hidden');
  }

  moveLeft() {
    this.scrollerTarget.scrollBy({
        top: 0,
        left: -200,
        behavior: 'smooth'
    });
    if (!this.hasLeftButtonTarget) return;
    if (this.scrollerTarget.scrollLeft < 300) {
        this.leftButtonTarget.classList.add('hidden');
    }
  }
}
