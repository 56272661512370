import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  connect() {
    const app = document.getElementById('mockup');

    (async () => {
      const React = await import('react');
      const ReactDOM = await import('react-dom/client');
      const Mockup = (await import('../components/mockup')).default;

      this.root = ReactDOM.createRoot(app);
      this.root.render(<Mockup app={app} />);
    })();
  }

  disconnect() {
    this.root.unmount();
  }
}
