import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back-button"
export default class extends Controller {
  static targets = ['text']

  connect() {
    if (history.length > 1) {
      this.element.setAttribute('href', 'javascript:history.back()');
      this.textTarget.textContent = "Back";
    }
  }
}
