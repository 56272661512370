import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="nav-menu"
export default class extends Controller {
  static targets = ['menu'];

  toggleMenu() {
    this.menuTarget.classList.toggle('hidden');
  }

  closeMenu() {
    this.menuTarget.classList.add('hidden');
  }
}
