import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="subject-form"
export default class extends Controller {
  static targets = [
    'previousButton',
    'step',
    'subjectTypeEmoji',
    'nameField',
    'name',
    'emailField',
    'errorMessage',
    'typeErrorMessage',
    'emailErrorMessage',
    'photoErrorMessage',
    'photoTips',
  ];
  static values = {
    step: Number,
    numPhotos: Number,
    minPhotos: Number,
    stylePacksUrl: String,
    subjectType: String,
  };

  connect() {
    this.maxSteps = this.stepTargets.length;
  }

  stepValueChanged() {
    this.toggleStep();
    this.updateName();
    this.hideErrorMessages();
  }

  toggleStep() {
    this.stepTargets.forEach((element) => {
      if (parseInt(element.dataset.step) != this.stepValue) {
        element.classList.add('hidden');
      } else {
        element.classList.remove('hidden');
      }
    });
  }

  updateName() {
    const name = this.nameFieldTarget.value || 'your pet';
    this.nameTargets.forEach((element) => (element.innerText = name));
  }

  typeChange(event) {
    this.subjectTypeEmojiTargets.forEach((element) => {
      element.innerText = event.target.value === 'dog' ? '🐕' : '🐈';
    });
    this.subjectTypeValue = event.target.value;
    this.errorMessageTarget.classList.add('hidden');
  }

  hideErrorMessages() {
    this.errorMessageTargets.forEach((element) => element.classList.add('hidden'));
  }

  handleHotkey(selector) {
    this.stepTargets.forEach((element) => {
      if (!element.classList.contains('hidden')) {
        element.querySelector(selector)?.click();
      }
    });
  }

  handleHotkeyPrevious() {
    this.handleHotkey('.subjectForm--previousBtn');
  }

  handleHotkeyNext() {
    this.handleHotkey('.subjectForm--nextBtn');
  }

  validateType(event) {
    if (this.subjectTypeValue) return;

    this.showErrorMessage(event, this.typeErrorMessageTarget);
  }

  // turbo will handle error if invalid email address is submitted
  submitGuestSubject(event) {
    if (this.element.checkValidity()) {
      event.target.disabled = true;
      this.element.requestSubmit();
    } else {
      this.element.reportValidity();
    }
  }

  validatePhotos(event) {
    if (this.numPhotosValue >= this.minPhotosValue) {
      window.location.href = this.stylePacksUrlValue;
      return;
    }

    this.showErrorMessage(event, this.photoErrorMessageTarget);
  }

  showErrorMessage(event, target) {
    target.classList.remove('hidden');
    event.stopImmediatePropagation();
  }

  // Dispatched from multiple-upload controller.
  handlePhotoCountChanged(numPhotos) {
    this.numPhotosValue = numPhotos;
    this.hideErrorMessages();
  }

  previousStep = () => (this.stepValue = Math.max(1, this.stepValue - 1));

  nextStep = () => (this.stepValue = Math.min(this.maxSteps, this.stepValue + 1));

  // Modify sign in link to include email and subject details so we can pre-fill and auto-create.
  clickSignInLink(event) {
    event.preventDefault();

    let url = new URL(event.target.href);
    if (this.emailFieldTarget.value) {
      url.searchParams.set('email_hint', this.emailFieldTarget.value);
    }
    if (this.nameFieldTarget.value) {
      url.searchParams.set('subject[name]', this.nameFieldTarget.value);
    }
    if (this.subjectTypeValue) {
      url.searchParams.set('subject[type]', this.subjectTypeValue);
    }
    url.searchParams.set('limit_login', true);

    window.location.href = url;
  }

  hideEmailErrorMessage() {
    if (this.hasEmailErrorMessageTarget) {
      this.emailErrorMessageTarget.classList.add('hidden');
    }
  }
}
