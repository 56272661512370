import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="newsletter-sync"
export default class extends Controller {
  static targets = ["oauthForm"]

  sync(event) {
    this.oauthFormTargets.forEach((form) => {
      const url = new URL(form.action)
      url.searchParams.set("newsletter", event.target.checked)
      form.action = url.toString();
    });
  }
}
