import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="custom-value-field"
export default class extends Controller {
  static targets = ['customFieldGroup', 'customField'];
  static values = { presetValues: Array, currentValue: String };

  connect() {
    this.initializeSelection();
  }

  // If the current value is not in the preset values, select the custom radio button and
  // display the custom field.
  initializeSelection() {
    if (!this.currentValueValue) return;
    const value = this.presetValuesValue.includes(this.currentValueValue)
      ? this.currentValueValue
      : 'custom';
    // Uncheck first so we can retrigger clicking on label
    this.element.querySelector(`input[type='radio'][value$='${value}']`).checked = false;
    this.element.querySelector(`label[for$='${value}']`).click();
  }

  selectRadio(event) {
    // Remove selected class from all labels
    this.element.querySelectorAll('label').forEach((label) => {
      label.classList.remove('giftCardForm--radioButtonLabel-selected');
    });
    // Add selected class to selected label
    document
      .querySelector(`label[for=${event.target.id}]`)
      .classList.add('giftCardForm--radioButtonLabel-selected');

    // Show custom field if custom radio button is selected and adjust required and pattern attrs
    if (event.target.value == 'custom') {
      this.customFieldGroupTarget.classList.remove('hidden');
      this.customFieldTarget.setAttribute('required', 'required');
      if (this.customFieldTarget.dataset.pattern) {
        this.customFieldTarget.removeAttribute('pattern');
      }
    } else {
      this.customFieldGroupTarget.classList.add('hidden');
      this.customFieldTarget.removeAttribute('required');
      if (this.customFieldTarget.dataset.pattern) {
        this.customFieldTarget.setAttribute('pattern', this.customFieldTarget.dataset.pattern);
      }
    }
  }
}
