import { Controller } from '@hotwired/stimulus';
import posthog from 'posthog-js';

// Connects to data-controller="posthog"
export default class extends Controller {
  static values = { captureParams: Array };

  connect() {
    this.captureParamsValue.forEach((params) => {
      posthog.capture(params.event, params.properties, { timestamp: params.timestamp });
    })
  }
}
