import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="filter-styles"
export default class extends Controller {
  static targets = ['searchInput', 'style', 'emptyState', 'styleName', 'suggestionModalLink'];

  searchValue = () => this.searchInputTarget.value;

  search() {
    this.numVisible = 0;
    this.filterStyles();
    this.handleEmptyState();
  }

  filterStyles() {
    const regexp = new RegExp(this.searchValue(), 'i');
    this.styleTargets.forEach((target) => {
      if (target.dataset.styleName.match(regexp)) {
        target.classList.remove('hidden');
        this.numVisible++;
      } else {
        target.classList.add('hidden');
      }
    });
  }

  handleEmptyState() {
    if (this.numVisible == 0) {
      // Display empty state
      this.emptyStateTarget.classList.remove('hidden');
      this.styleNameTarget.innerHTML = `"${this.searchValue()}"`;
      this.prefillSuggestionModal(this.searchValue());
    } else {
      // Hide empty state
      this.emptyStateTarget.classList.add('hidden');
    }
  }

  prefillSuggestionModal() {
    let url = new URL(this.suggestionModalLinkTarget.href);
    url.searchParams.set('body', `I'd like to see a "${this.searchValue()}" style!`);
    this.suggestionModalLinkTarget.href = url.toString();
  }
}
