import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["button", "quantity"]

    connect() {
        this.originalQuantity = this.quantityTarget.value
    }

    change(event) {
        if (event.target.value != this.originalQuantity) {
            this.buttonTarget.hidden = false
        } else {
            this.buttonTarget.hidden = true
        }
    }
}