// Polls server for image URL. Used with Products::ThumbnailComponent and products/image.html.erb
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="product-image"
export default class extends Controller {
  connect() {
    const pollImage = setInterval(() => {
      fetch(this.element.dataset.requestUrl)
        .then((response) => {
          response.json().then((data) => {
            if (!data.url) return;

            clearInterval(pollImage);
            this.updateImageSrc(data.url);
          });
        })
        .catch(() => {
          clearInterval(pollImage);
        });
    }, 3000)
  }

  updateImageSrc(url) {
    this.element.src = url;
    this.element.onload = () => {
      this.element.classList.remove('image-loading-spinner')
    };
  }
}
