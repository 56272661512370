import { Controller } from '@hotwired/stimulus';
import { put } from '@rails/request.js';

let WAIT_THRESHOLD = 60; // seconds
let INTERVAL_CHECK = 5000; // milliseconds

// Connects to data-controller="photo"
export default class extends Controller {
  static values = { createdAt: Number, updatePhotoUrl: String };

  connect() {
    if (!this.element.querySelector('.fa-spin')) return;

    // If the photo hasn't auto-cropped within WAIT_THRESHOLD, force crop it.
    if (this.waitedOverThreshold()) {
      this.forceCropImage();
    } else {
      // If it hasn't been over WAIT_THRESHOLD, check every INTERVAL_CHECK until it has.
      this.checkPhotoInterval = setInterval(() => {
        if (this.waitedOverThreshold()) {
          this.forceCropImage();
          clearInterval(this.checkPhotoInterval);
        }
      }, INTERVAL_CHECK);
    }
  }

  disconnect() {
    clearInterval(this.checkPhotoInterval);
  }

  waitedOverThreshold() {
    return this.createdAtValue + WAIT_THRESHOLD < Math.floor(Date.now() / 1000);
  }

  forceCropImage() {
    put(this.updatePhotoUrlValue, { responseKind: 'turbo-stream' });
  }
}
