import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["subject"]

    selectSubject(event) {
        const frame = document.getElementById('art_images');
        frame.src = event.target.value;
    }
}
