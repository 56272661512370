import { Controller } from '@hotwired/stimulus';
import debounce from 'lodash.debounce';

// Unlike mini-gallery controller, this will scroll based on the dynamic width of the prev/next
// sibling, rather than a fixed width
// Would to nice to accept a parameter to scroll n items, rather than 1 at a time
// Connects to data-controller="daisy-carousel-navigation"
export default class extends Controller {
  static targets = ['carousel', 'carouselItem', 'buttonReference', 'previousButton', 'nextButton'];

  connect() {
    // Debounce method to vertically center the previous/next buttons
    this.debouncedCenterButtons = debounce(this.verticallyCenterButtons.bind(this), 250);
    window.addEventListener('resize', this.debouncedCenterButtons);
    // Initial vertical centering of the previous/next buttons
    this.centerButtonsAfterFirstImageLoad();
  }

  disconnect() {
    window.removeEventListener('resize', this.debouncedCenterButtons);
  }

  centerButtonsAfterFirstImageLoad() {
    const firstImage = this.carouselTarget.querySelector('img');
    if (firstImage && !firstImage.complete) {
      firstImage.onload = this.verticallyCenterButtons.bind(this);
    } else {
      // If the image is already loaded or there's no image, center immediately
      this.verticallyCenterButtons();
    }
  }

  previous() {
    this.updateVisibleChildren();
    if (this.visibleChildren[0].previousSibling) {
      // Scroll to the previous sibling
      this.scrollIntoView(this.visibleChildren[0].previousSibling);
    } else {
      // If there are no more previous siblings to scroll to, scroll to the last child
      this.scrollIntoView(this.carouselItemTargets[this.carouselItemTargets.length - 1]);
    }
  }

  next() {
    this.updateVisibleChildren();
    if (
      this.visibleChildren[this.visibleChildren.length - 1] ==
      this.carouselItemTargets[this.carouselItemTargets.length - 1]
    ) {
      // If the last child is visible, scroll back to the first child
      this.scrollIntoView(this.carouselItemTargets[0]);
    } else if (this.visibleChildren[0].nextSibling) {
      // Scroll to the next sibling
      this.scrollIntoView(this.visibleChildren[0].nextSibling);
    } else {
      // If there are no more next siblings to scroll to, scroll back to the first child
      this.scrollIntoView(this.carouselItemTargets[0]);
    }
  }

  // Update the visible children based on the current carousel's bounding rectangle to determine
  // the previous/next children to scroll to
  updateVisibleChildren() {
    const carouselRect = this.carouselTarget.getBoundingClientRect();
    this.visibleChildren = this.carouselItemTargets.filter((child) => {
      const childRect = child.getBoundingClientRect();
      // Check if the child's bounding rectangle is within the carousel's bounding rectangle
      // 10 is just an arbitrary number to make sure the child is mostly within the carousel
      return childRect.left >= carouselRect.left - 10 && childRect.right <= carouselRect.right + 10;
    });
  }

  scrollIntoView(element) {
    this.carouselTarget.scroll({
      left: element.offsetLeft,
      behavior: 'smooth',
    });
  }

  // Vertically center button based on the half height of the buttonReference target
  verticallyCenterButton(element) {
    element.style.top =
      this.buttonReferenceTargets[0].getBoundingClientRect().height / 2 -
      element.getBoundingClientRect().height / 2 +
      'px';
  }

  verticallyCenterButtons() {
    this.verticallyCenterButton(this.previousButtonTarget);
    this.verticallyCenterButton(this.nextButtonTarget);
  }
}
