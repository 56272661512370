import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="art-image-products"
export default class extends Controller {
  static targets = ["products"]

  selectImage(e) {
    this.productsTarget.src = e.params.url
    window.history.pushState(window.history.state, '', e.params.url);
  }
}
