import { Controller } from '@hotwired/stimulus';
import debounce from "lodash.debounce";

// Connects to data-controller="carousel"
export default class extends Controller {
  static targets = ['backLink', 'spacer', 'scroller', 'scrollItem'];

  connect() {
    window.addEventListener('resize', this.debouncedUpdateScrollOffset);
  }

  disconnect() {
    window.removeEventListener('resize', this.debouncedUpdateScrollOffset);
  }

  updateScrollOffset() {
    let offset = this.backLinkTarget.offsetLeft;
    this.spacerTargets.forEach((el) => {
      el.style.width = `${offset}px`;
    });
    this.scrollItemTargets.forEach((el) => {
      el.style.scrollMarginLeft = `${offset}px`;
    });
  }

  debouncedUpdateScrollOffset = debounce(() => {
    this.updateScrollOffset();
  }, 100);

  // Scroll image into view
  scrollImageIntoView(element, behavior) {
    this.scrollerTarget.scrollTo({
      top: 0,
      left: element.offsetParent.offsetLeft - this.backLinkTarget.offsetLeft,
      behavior: behavior,
    });
  }

  // Scroll initial active image into view
  imageTargetConnected(element) {
    this.displayStarOnImageLoad(element);
    if (!element.previousSibling.checked) return;

    this.updateScrollOffset();
    setTimeout(() => {
      this.scrollImageIntoView(element, 'auto');
    }, 0) // wait for this.updateScrollOffset() to finish
  }

  // Scroll active image into view when image is selected
  selectImage(e) {
    this.scrollImageIntoView(e.target, 'smooth');
  }

  // Due to image lazy loading, we do not want to display the star until the image is loaded
  displayStarOnImageLoad(image) {
    if (!image.nextSibling) return;
    if (image.complete) image.nextSibling.classList.remove('hidden');
    image.onload = () => {
      image.nextSibling.classList.remove('hidden');
    };
  }
}
