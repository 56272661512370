import { Controller } from '@hotwired/stimulus';

let Clipboard = null;

// Connects to data-controller="clipboard"
export default class extends Controller {
  static targets = ['content'];

  initialize() {
    (async () => {
      Clipboard = (await import('clipboard')).default;
      new Clipboard(this.element).on('success', () => {
        this.toggleContent();
        setTimeout(this.toggleContent.bind(this), 2000);
      });
    })();
  }

  toggleContent() {
    this.contentTargets.forEach((content) => {
      content.classList.toggle('hidden');
    });
  }
}
