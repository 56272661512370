import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-content"
export default class extends Controller {
  static targets = [ "toggle" ]

  toggle() {
    this.toggleTargets.forEach((target) => {
      document.querySelector(target.dataset.toggleContentSelector).classList.toggle("hidden");
    })
  }
}
