import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="meta-pixel-events"
export default class extends Controller {
  static targets = ['event'];

  eventTargetConnected(element) {
    const { event, customData, eventId } = element.dataset;
    fbq('track', event, JSON.parse(customData), { event_id: eventId });
  }
}
