import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="examples"
export default class extends Controller {
  static targets = ['type'];

  toggle(event) {
    if (event.target.value == 'dog_breeds') {
      this.typeTarget.innerText = 'dog ';
      window.history.replaceState({}, '', '?view=dogs');
    } else {
      this.typeTarget.innerText = 'cat ';
      window.history.replaceState({}, '', '?view=cats');
    }
  }
}
