import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="gallery-lightbox"
export default class extends Controller {
  static targets = ['image', 'prevLink', 'nextLink'];

  connect() {
    // Locate the current image in the gallery
    const allGalleryLightboxLinks = document.querySelectorAll('.galleryLightboxLink');
    const link = [...allGalleryLightboxLinks].find(
      (link) => link.href == window.location.href || link.href.startsWith(window.location.href),
    );

    // Find scoped gallery container if it exists, otherwise navigate across entire document
    const container = link.closest('.galleryLightboxContainer') || document;

    // Locate the current image idx in the scoped gallery container
    this.galleryLightboxLinks = container.querySelectorAll('.galleryLightboxLink');
    const idx = [...this.galleryLightboxLinks].findIndex(
      (link) => link.href == window.location.href || link.href.startsWith(window.location.href),
    );

    // Hide navigation links if we can't find the image in the gallery
    if (idx === -1) {
      this.prevLinkTarget.remove();
      this.nextLinkTarget.remove();
      return;
    }

    // Update the gallery lightbox
    this.updateNavigation(idx);
  }

  updateContent(idx) {
    document.getElementById('modal').src = this.galleryLightboxLinks[idx].href;
    // Update the browser history
    window.history.pushState(
      { modalSrc: this.galleryLightboxLinks[idx].href },
      '',
      this.galleryLightboxLinks[idx].href,
    );
  }

  updateNavigation(idx) {
    // Wrap around the gallery images
    this.prevIdx = idx - 1 < 0 ? this.galleryLightboxLinks.length - 1 : idx - 1;
    this.nextIdx = idx + 1 > this.galleryLightboxLinks.length - 1 ? 0 : idx + 1;

    // Set the navigation links
    this.prevLinkTarget.href = this.galleryLightboxLinks[this.prevIdx].href;
    this.nextLinkTarget.href = this.galleryLightboxLinks[this.nextIdx].href;
  }

  prev(event) {
    event.preventDefault();
    if (this.prevIdx == null) return;

    this.updateContent(this.prevIdx);
    this.updateNavigation(this.prevIdx);
  }

  next(event) {
    event.preventDefault();
    if (this.nextIdx == null) return;

    this.updateContent(this.nextIdx);
    this.updateNavigation(this.nextIdx);
  }
}
