import { Controller } from '@hotwired/stimulus';
import { get, post, patch } from '@rails/request.js';

// Allows scrolling of filmstrip with mouse wheel in vertical or horizontal direction
function transformScroll(event) {
  event.preventDefault();
  if (!event.deltaY) {
    return;
  }
  event.currentTarget.scrollLeft += event.deltaY + event.deltaX;
}

// Connects to data-controller="lightbox"
export default class extends Controller {
  static targets = [
    'filmstrip',
    'currentFilmstripImage',
    'filmstripImage',
    'displayImage',
    'previousImage',
    'nextImage',
    'previousStyle',
    'nextStyle',
    'content',
    'closeButton',
  ];

  closeWithKeyboard() {
    this.closeButtonTarget.click();
  }

  closeBackground(e) {
    const content = this.contentTargets.some((contentTarget) => contentTarget.contains(e.target));
    if (content) return;
    this.closeButtonTarget.click();
  }

  filmstripTargetConnected(target) {
    target.addEventListener('wheel', transformScroll);
  }

  filmstripTargetDisconnected(target) {
    target.removeEventListener('wheel', transformScroll);
  }

  // Scroll immediately to current filmstrip image
  currentFilmstripImageTargetConnected(currentTarget) {
    this.scrollIntoView(currentTarget, 'instant');
  }

  // Find image in filmstrip and select it
  displayImageTargetConnected(target) {
    this.filmstripImageTargets.forEach((image) => {
      if (image.dataset.imageId == target.dataset.imageId) {
        image.classList.add('border-4');
        this.scrollIntoView(image, 'smooth');
      } else {
        image.classList.remove('border-4');
      }
    });
  }

  // We don't want to use JS's built-in scrollIntoView because we don't want to vertically scroll
  // the target into view, only horizontally.
  scrollIntoView(target, behavior) {
    this.filmstripTarget.scrollTo({
      top: 0,
      left: target.offsetParent.offsetLeft - window.innerWidth / 2 + target.offsetWidth / 2,
      behavior: behavior,
    });
  }

  navigateToPreviousStyle(event) {
    event.preventDefault(); // Prevent scrolling div with left key
    this.previousStyleTarget.click();
  }

  navigateToNextStyle(event) {
    event.preventDefault(); // Prevent scrolling div with right key
    this.nextStyleTarget.click();
  }

  navigateToPreviousImage(event) {
    event.preventDefault(); // Prevent scrolling div with left key
    if (this.hasPreviousImageTarget) {
      this.previousImageTarget.click();
    } else {
      this.previousStyleTarget.click();
    }
  }

  navigateToNextImage(event) {
    event.preventDefault(); // Prevent scrolling div with right key
    if (this.hasNextImageTarget) {
      this.nextImageTarget.click();
    } else {
      this.nextStyleTarget.click();
    }
  }

  rateImage(event) {
    event.preventDefault(); // Prevent form from submitting directly
    const form = event.target;
    const url = form.dataset.rateImageUrl;
    const method = form.elements._method ? patch : post;

    method(form.action, {
      body: new FormData(form),
      responseKind: 'turbo-stream',
    }).then((result) => {
      if (result.response.redirected) {
        window.location.href = result.response.url;
        return;
      }
      get(url, { headers: { 'Turbo-Frame': 'modal' }, responseKind: 'turbo-stream' });
      window.history.replaceState({ modalSrc: url }, '', url);
    });
  }
}
