import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="finetuning-style-pack-selector"
export default class extends Controller {
  static targets = ['stylePackName', 'stylePackIdField', 'label']
  connect() {
  }

  select(event) {
    const style_pack_name = event.target.dataset.stylePackName;
    const style_pack_id = event.target.value;
    this.stylePackNameTarget.innerHTML = style_pack_name;
    this.stylePackIdFieldTarget.value = style_pack_id;
    this.labelTargets.forEach((label) => {
      if (label.dataset.stylePackId == style_pack_id) {
        label.querySelector("span").innerText = "Selected";
        label.querySelector("svg").classList.remove("hidden");
      } else {
        label.querySelector("span").innerText = "Select pack"
        label.querySelector("svg").classList.add("hidden");
      }
    })
  }
}
