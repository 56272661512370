import { Controller } from '@hotwired/stimulus';

let POLL_INTERVAL = 5000;

// Connects to data-controller="download-art-images"
export default class extends Controller {
  static targets = ['inProgressForm', 'autoDownloadLink'];

  inProgressFormTargetConnected(form) {
    setTimeout(() => {
      form.requestSubmit();
    }, POLL_INTERVAL);
  }

  autoDownloadLinkTargetConnected(link) {
    window.location.href = link.href;
  }
}
