import { Controller } from '@hotwired/stimulus';
import confetti from 'canvas-confetti';

// Connects to data-controller="subject-style-packs-index"
export default class extends Controller {
  static targets = ['lineItems', 'freeStylePacksModal'];

  lineItemsTargetConnected() {
    this.lineItemsTarget.scrollTop = this.lineItemsTarget.scrollHeight;
  }

  freeStylePacksModalTargetConnected(target) {
    const confettiCanvas = document.createElement('canvas');
    confettiCanvas.style.position = 'fixed';
    confettiCanvas.style.width = '100%';
    confettiCanvas.style.height = '100%';
    confettiCanvas.style.top = '0';
    confettiCanvas.style.left = '0';
    confettiCanvas.style.pointerEvents = 'none';
    target.appendChild(confettiCanvas);
    this.myConfetti = confetti.create(confettiCanvas, { resize: true });
  }

  addConfetti() {
    const count = 200;
    const defaults = {
      origin: { y: 0.7 },
      colors: ['12b7a8', '77c3b8', 'F24B4B', 'FFCA6C', 'FDFFB8'],
    };

    const fire = (particleRatio, opts) => {
      this.myConfetti({
        ...defaults,
        ...opts,
        particleCount: Math.floor(count * particleRatio),
      });
    };

    fire(0.25, { spread: 26, startVelocity: 55 });
    fire(0.2, { spread: 60 });
    fire(0.35, { spread: 100, decay: 0.91, scalar: 0.8 });
    fire(0.1, { spread: 120, startVelocity: 25, decay: 0.92, scalar: 1.2 });
    fire(0.1, { spread: 120, startVelocity: 45 });
  }
}
