import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="field-character-counter"
export default class extends Controller {
  static targets = ["remainingCharacters", "field"]
  static values = { maxLength: Number }

  connect() {
    this.updateCharacterCount();
  }

  updateCharacterCount() {
    const remaining = this.maxLengthValue - this.fieldTarget.value.length;
    this.remainingCharactersTarget.innerHTML =
      remaining > 1 ? `${remaining} characters` : `${remaining} character`;
  }
}
