import { Controller } from '@hotwired/stimulus';

// Hacky way to force a full redirect from a turbo stream request
// Used for situations where the we want a full redirect on success but a turbo stream on error
// Connects to data-controller="redirect"
export default class extends Controller {
  static values = {
    url: String,
  };

  connect() {
    window.location.href = this.urlValue;
  }
}
