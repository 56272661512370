import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="content-toggle"
export default class extends Controller {
  connect() {
    this.element.addEventListener('click', (event) => {
      if (this.element.querySelector('summary').contains(event.target)) {
        this.element.toggleAttribute('open');
      }
    });
  }
}
