import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="hero-subject"
export default class extends Controller {
  static targets = ["artImages", "favoriteImages"]
  static values = {images: Object }

  swap(e) {
    this.artImagesTarget.src = this.imagesValue[e.target.value].art;
    this.favoriteImagesTarget.src = this.imagesValue[e.target.value].favorites;
  }
}
