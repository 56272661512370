import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="product-image-previews"
export default class extends Controller {
  static targets = ["previewLink"]

  select(event) {
    this.previewLinkTargets.forEach((previewLink) => {
      if (previewLink == event.currentTarget) {
        previewLink.classList.add("productImagePreviews--thumbnail-is-selected")
      } else {
        previewLink.classList.remove('productImagePreviews--thumbnail-is-selected');
      }
    })
  }
}
