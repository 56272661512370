import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

// Connects to data-controller="product-preview"
export default class extends Controller {
  static values = { url: String };

  connect() {
    this.interval = setInterval(() => {
      // Only replace when art image exists, which is handled by the product-image controller
      if (!this.element.querySelector('img').getAttribute('src')) return;

      get(this.urlValue, { responseKind: 'turbo-stream' });
      clearInterval(this.interval);
    }, 3000);
  }

  disconnect() {
    clearInterval(this.interval);
  }
}
