import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="disable-form-with-checkbox"
export default class extends Controller {
  static targets = ['alert', 'checkbox'];

  connect() {
    this.checkboxTarget.addEventListener('change', (e) => {
      if (e.target.checked) {
        this.alertTarget.classList.add('hidden');
      }
    });
  }

  handleSubmit(event) {
    // If checkbox is hidden, don't do anything. This is so we don't block the submit on the
    // finetuning feedback form when the checkbox is hidden.
    if (this.checkboxTarget.clientHeight == 0) return;
    if (!this.checkboxTarget.checked) {
      event.preventDefault();
      // Ensure subsequent actions are not invoked.
      event.stopImmediatePropagation();
      this.alertTarget.classList.remove('hidden');
    }
  }
}
