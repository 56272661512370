import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="prompt-ratings-form"
export default class extends Controller {
  static values = { promptId: Number };

  fadeOut(element) {
    if (!element) return;
    element.style.height = element.clientHeight + 'px';
    element.classList.add('fadeIn');

    this.element.addEventListener(
      'submit',
      () => {
        element.remove();
      },
      { once: true },
    );
    setTimeout(() => {
      element.classList.add('overflow-hidden');
      element.style.height = '0px';
      setTimeout(() => {
        element.addEventListener(
          'transitionend',
          () => {
            this.element.requestSubmit();
          },
          { once: true },
        );
      }, 500);
    }, 200);
  }

  hideStyle(event) {
    event.preventDefault();
    this.fadeOut(document.getElementById(`gallery_pick_favorites_prompt_${this.promptIdValue}`));
  }

  unhideStyle(event) {
    event.preventDefault();
    this.fadeOut(document.getElementById(`gallery_hidden_prompt_${this.promptIdValue}`));
  }
}
