import { Controller } from '@hotwired/stimulus';
import InApp from 'detect-inapp';

// Due to security reasons, Google, Apple, and Facebook do not allow in-app browsers to authenticate
// users, so we need to disable the sign up/in buttons and explain to users to use a native browser.
// Connects to data-controller="in-app-browser"
export default class extends Controller {
  static targets = ['signUpButton', 'signUpDivider', 'signInButton', 'explanation'];

  initialize() {
    this.inapp = new InApp(navigator.userAgent || navigator.vendor || window.opera);
  }

  // Hide sign up buttons if in-app browser
  signUpButtonTargetConnected(target) {
    if (this.inapp.isInApp) {
      target.classList.add('hidden');
    }
  }

  // Hide sign up divider if in-app browser
  signUpDividerTargetConnected(target) {
    if (this.inapp.isInApp) {
      target.classList.add('hidden');
    }
  }

  // Disable sign in buttons if in-app browser
  signInButtonTargetConnected(target) {
    if (this.inapp.isInApp) {
      target.setAttribute('disabled', true);
    }
  }

  // Show explanation if in-app browser
  explanationTargetConnected(target) {
    if (this.inapp.isInApp) {
      target.classList.remove('hidden');
    }
  }
}
