import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="actionable-photo"
export default class extends Controller {
  static targets = ['photo', 'cropBtn'];

  // If there's a spinner inside of actionable-photo, disable the crop button, otherwise, enable it.
  photoTargetConnected() {
    if (this.element.querySelector('.fa-spin')) {
      this.cropBtnTarget.classList.add('actionablePhoto--action-disabled');
    } else {
      this.cropBtnTarget.classList.remove('actionablePhoto--action-disabled');
    }
  }
}
