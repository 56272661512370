import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="gallery-image"
export default class extends Controller {
  static targets = ['image', 'starredOverlay', 'rejectedOverlay', 'errorOverlay', 'star', 'reject'];
  static values = { starEvent: String, rejectEvent: String, url: String };

  disconnect() {
    clearTimeout(this.errorOverlayTimeout);
  }

  errorOverlayTargetConnected(target) {
    this.errorOverlayTimeout = setTimeout(() => {
      target.classList.remove('fadeIn-visible');
    }, 3000);
  }

  // Need to dispatch event to every same gallery image since we can have the same gallery image
  // across the groups.
  dispatchStar() {
    const event = new CustomEvent(this.starEventValue);
    window.dispatchEvent(event);
  }

  dispatchAddToFavoritesHeader(event) {
    window.dispatchEvent(
      new CustomEvent(`add-to-favorites-header`, {
        detail: { ...event.params },
      }),
    );
  }

  star() {
    this.hasRejectedOverlayTarget && this.rejectedOverlayTarget.classList.add('hidden');

    if (this.starTarget.classList.contains('galleryImage--btn-star-selected')) {
      this.starTarget.classList.remove('galleryImage--btn-star-selected');
      this.hasStarredOverlayTarget && this.starredOverlayTarget.classList.add('hidden');
    } else {
      this.starTarget.classList.add('galleryImage--btn-star-selected');
      this.rejectTarget.classList.remove('galleryImage--btn-reject-selected');
      this.hasStarredOverlayTarget && this.starredOverlayTarget.classList.remove('hidden');
    }
  }

  // Need to dispatch event to every same gallery image since we can have the same gallery image
  // across the groups.
  dispatchReject() {
    const event = new CustomEvent(this.rejectEventValue);
    window.dispatchEvent(event);
  }

  dispatchRemoveFromFavoritesHeader(event) {
    window.dispatchEvent(
      new CustomEvent(`remove-from-favorites-header`, {
        detail: { ...event.params },
      }),
    );
  }

  reject() {
    this.hasStarredOverlayTarget && this.starredOverlayTarget.classList.add('hidden');

    if (this.rejectTarget.classList.contains('galleryImage--btn-reject-selected')) {
      this.rejectTarget.classList.remove('galleryImage--btn-reject-selected');
      this.hasRejectedOverlayTarget && this.rejectedOverlayTarget.classList.add('hidden');
    } else {
      this.rejectTarget.classList.add('galleryImage--btn-reject-selected');
      this.starTarget.classList.remove('galleryImage--btn-star-selected');
      this.hasRejectedOverlayTarget && this.rejectedOverlayTarget.classList.remove('hidden');
    }
  }
}
