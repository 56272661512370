import { Controller } from '@hotwired/stimulus';

const closeModalEvent = new CustomEvent('close-modal');

let CustomizedCropper = null;

async function extendCropper() {
  const Cropper = (await import('cropperjs')).default;

  return class extends Cropper {
    setCropBoxData(data) {
      // decimal margin fix
      data.width = Math.max(
        Math.min(data.width, this.cropBoxData.maxWidth),
        this.cropBoxData.minWidth,
      );
      data.height = Math.max(
        Math.min(data.height, this.cropBoxData.maxHeight),
        this.cropBoxData.minHeight,
      );
      return super.setCropBoxData.call(this, data);
    }
  };
}

// Connects to data-controller="photo-cropper"
export default class extends Controller {
  static targets = ['image', 'form', 'cropFormField'];

  async connect() {
    if(!CustomizedCropper) {
      CustomizedCropper = await extendCropper();
    }

    // Remove focus from the button that opened the modal so user can use 'enter' key to submit
    document.activeElement.blur();

    // Create a placeholder image to initialize the cropper with the correct image dimensions
    const placeholderImg = this.createPlaceholderImage();
    this.imageTarget.src = placeholderImg.src;

    // Instantiate cropper
    this.cropper = new CustomizedCropper(this.imageTarget, {
      aspectRatio: 1,
      checkCrossOrigin: false,
      data: JSON.parse(this.cropFormFieldTarget.value),
      dragMode: 'move',
      minCropBoxWidth: 100,
      toggleDragModeOnDblclick: false,
      viewMode: 1,
      ready: () => {
        // Until the image is loaded, hide the cropper container so the spinner is visible
        this.cropper.cropper.classList.add('invisible');
        this.cropper.replace(this.imageTarget.dataset.src, true);

        var img = new Image();
        img.src = this.imageTarget.dataset.src;
        img.onload = () => {
          this.cropper.cropper.classList.remove('invisible');
        };
      },
      crop: (event) => {
        const value = (({ x, y, width, height, rotate }) => ({ x, y, width, height, rotate }))(
          event.detail,
        );
        this.cropFormFieldTarget.value = JSON.stringify(value);
      },
    });
  }

  // Creates a placeholder image that matches the dimensions of the target image being cropped
  createPlaceholderImage() {
    var canvas = document.createElement('canvas');
    canvas.width = this.imageTarget.dataset.width;
    canvas.height = this.imageTarget.dataset.height;

    var img = new Image();
    var dataURL = canvas.toDataURL('image/png');
    img.src = dataURL;
    img.classList = this.imageTarget.classList;

    return img;
  }

  rotate = () => this.cropper.rotate(-90);

  save(e) {
    e.preventDefault(); // Prevent default form submit
    this.formTarget.requestSubmit();
    window.dispatchEvent(closeModalEvent);
  }

  saveWithKeyboard(e) {
    if (e.code == 'Enter') this.save(e);
  }
}
