import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="tiktok-event"
export default class extends Controller {
  static targets = ['event'];

  eventTargetConnected(element) {
    const { event, properties, eventId } = element.dataset;
    ttq.track(event, JSON.parse(properties), { event_id: eventId });
  }
}
